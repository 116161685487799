
// GOOGLE TAG MANAGER COOKIE SETTINGS
const acceptButton = document.querySelector("#cookies-accept");
const rejectButton = document.querySelector("#cookies-reject");

acceptButton.addEventListener("click", function () {
  localStorage.setItem("consentGranted", "true");

  // G-TAG Function
  function gtag() {
    dataLayer.push(arguments);
  }

  gtag("consent", "update", {
    ad_user_data: "granted",
    ad_personalization: "granted",
    ad_storage: "granted",
    analytics_storage: "granted",
  });
});

// Load gtag.js script.
var gtagScript = document.createElement("script");
gtagScript.async = true;
gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-FQGDFG27PQ";

var firstScript = document.getElementsByTagName("script")[0];
firstScript.parentNode.insertBefore(gtagScript, firstScript);

rejectButton.addEventListener("click", function () {
  localStorage.setItem("consentDenied", "false");
  // G-TAG Function
  function gtag() {
    dataLayer.push(arguments);
  }

  gtag("consent", "update", {
    ad_user_data: "denied",
    ad_personalization: "denied",
    ad_storage: "denied",
    analytics_storage: "denied",
  });
});

// Load gtag.js script.
var gtagScript = document.createElement("script");
gtagScript.async = false;
gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-FQGDFG27PQ";

var firstScript = document.getElementsByTagName("script")[0];
firstScript.parentNode.insertBefore(gtagScript, firstScript);
